<template>
  <div class="tables-basic">
    <div class="type-box">
      <div class="card-box">
        <span>业务类型：</span>
        <p
          :class="typeVal == item ? 'in-val':''"
          @click="selectType(item)"
          v-for="item in typeList"
          :key="item">
          {{item}}
        </p>
      </div>
    </div>

    <div class="search-box">
      <button
        class="btn"
        :class="typeVal2 == item2 ? 'in-btn':''"
        @click="selectType2(item2)"
        v-for="item2 in typeList2"
        :key="item2">
        {{item2}}
        <i class="el-icon-d-caret el-icon--right" v-if="item2 != '综合'"></i>
      </button>
      <span class="span1">价格</span>
      <el-input placeholder="￥" class="inpt">
      </el-input>
      <span class="span2"> — </span>
      <el-input placeholder="￥" class="inpt inpt2">
        <i class="el-icon-search"></i>
      </el-input>
      <button class="btn2">
        <i class="el-icon-search"></i>
      </button>
      <span style="flex:1;"></span>
      <span>搜索</span>
      <el-input placeholder="请输入关键词" class="inpt3">
      </el-input>
    </div>

    <div class="centent-box">
      <div class="left-box">
        <div class="card" v-for="item3 in 7" :key="item3">
          <div class="title-box">
            <p class="title">任务</p>
            <p>法律咨询服务</p>
            <span style="flex:1;"></span>
            <div class="cost-box">
              <p class="cost">￥10000</p>
              <p>可议价</p>
            </div>
          </div>
          <div class="val-box">
            项目需求内容描述项目需求内容描述项目需求内容描述项目需求内容描述项目需求内容描述项目需求内容描述项目需
            求内容描述项目需求内容描述项目需求内容描述项目需求内容描述项目需求内容描述项目需求内容描述
            描述项目需求内容描述项目需求内容描述项目需求内容描述描述项目需求内容描述项目需求内容描述项目需求内容描述
          </div>
          <div class="floot-box">
            <p>马冬梅 ｜ 广东-深圳</p>
            <p>发布时间：2022-07-19</p>
            <span style="flex:1;"></span>
            <img src="@/assets/images/ywc/ck-icon.png" alt="">
            <p>96人</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TablesBasic",
  mounted() {
  },
  data() {
    return {
      typeList: [
        '全部','常年法律顾问','劳动仲裁诉讼代理','法律咨询服务','文本审查与制订','律师函与法律意见书',
        '海外用工','人力资源合规审计','人力资源规章审查修订','裁员外包','劳资谈判与纠纷调解','培训业务','其他'
      ],
      typeVal: '全部',
      typeList2: ['综合','按价格','按发布时间'],
      typeVal2: '综合',
      currentPage: 1,
      interPage: 20,
      dataSize: 0,
    };
  },
  methods: {
    selectType(val) {
      this.typeVal = val;
    },
    selectType2(val) {
      this.typeVal2 = val;
    },
  },
};
</script>
<style scoped lang="scss">
.tables-basic{
  position: relative;
  margin-left: 15px;
  padding-top: 120px;
  .type-box {
    position: absolute;
    top: -40px;
    left: 0;
    width: calc(100% - 15px);
    height: 140px;
    background-color: #fff;
    border-radius: 0 0 18px 18px;
    .card-box {
      display: flex;
      flex-wrap: wrap;
      margin: 15px 26px;
      border-top: 1px solid #E7E7E7;
      padding: 14px 0;
      span {
        font-size: 18px;
        line-height: 44px;
        color: #909399;
      }
      p {
        height: 44px;
        line-height: 44px;
        padding: 0 15px;
        font-size: 18px;
        color: #333;
        border-radius: 12px;
        margin-right: 10px;
        margin-bottom: 6px;
        cursor:pointer;
      }
      .in-val {
        color: #6474C7;
        background: linear-gradient(270deg, #F0E4FD 0%, #E6E4FD 100%);
      }
    }
  }

  ::v-deep .search-box {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% - 15px);
    height: 80px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(152,172,190,0.2);
    border-radius: 18px;
    padding: 0 20px;
    .btn {
      height: 40px;
      border: 1px solid #D7DBDA;
      background-color: #fff;
      border-radius: 8px;
      margin-right: 10px;
      padding: 0 20px;
      font-size: 18px;
      color: #909399;
      i {
        color: #D8D8D8;
      }
    }
    .in-btn {
      color: #6474C7;
      border: 1px solid #6474C7;
    }
    .inpt {
      width: 120px;
      height: 40px;
      border-radius: 8px;
      .el-input__inner {
        font-size: 18px;
      }
    }
    .inpt2 {
      width: 160px !important;
    }
    .inpt3 {
      width: 300px;
      height: 40px;
      margin-left: 10px;
      .el-input__inner {
        width: 100%;
        height: 100%;
        font-size: 18px !important;
        color: #909399;
        background: #EAF5FF !important;
        border-radius: 8px;
      }
    }
    .btn2 {
      width: 40px;
      height: 38px;
      border: none;
      margin-left: -42px;
      background-color: #fff;
      border-left: 1px solid #D7DBDA;
      z-index: 99;
    }
    span {
      font-size: 18px;
      color: #333;
    }
    .span1 {
      margin: 0 10px 0 80px;
    }
    .span2 {
      margin: 0 6px;
    }
  }

  .centent-box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .left-box {
      width: 72%;
      .card {
        height: 160px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(152,172,190,0.2);
        border-radius: 18px;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
        .title-box {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          .title {
            max-width: 520px;
            font-size: 20px;
            line-height: 20px;
            font-weight: bold;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: 0;
          }
          .cost-box {
            text-align: right;
            p {
              font-size: 16px;
              line-height: 22px;
              color: #FFC048;
              margin-bottom: 0;
            }
            .cost {
              font-size: 20px;
              color: #6474C7;
            }
          }
        }
        .val-box {
          width: 100%;
          height: 46px;
          font-size: 16px;
          color: #A09E9E;
          line-height: 22px;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 10px;
        }
        .floot-box {
          display: flex;
          align-items: center;
          p {
            line-height: 16px;
            margin-bottom: 0;
          }
        }
      }
    }
    .right-box {
      width: 25%;
    }
  }
}
</style>
